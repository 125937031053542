import wretch from "wretch";
import { useQuery, useMutation } from "react-query";

import { getSession } from "../contexts/AuthContext";

const API_ROOT = process.env.REACT_APP_API_ROOT;
const APP_HOST = process.env.REACT_APP_APP_HOST;

export function apiUrl(path) {
  const url = API_ROOT + "/api/" + path;
  ////console.log("url", url);
  return url;
}

export function createSession(session) {
  ////console.log("creating session");
}

export function useApiQuery(queryKey, path, { options } = {}) {
  // TODO: Redirect to /login if 401 returned
  return useQuery(queryKey, (variables) => getApi(path), options);
}

export function useApiMutation(path, { method = "post", body, options } = {}) {
  // TODO: Redirect to /login if 401 returned
  return useMutation(
    (variables) => requestApi({ path, method, body }),
    options
  );
}

export async function getApi(path) {
  const headers = await getSession();
  return wretch(apiUrl(path))
    .headers(headers)
    .get()
    .notFound((error) => {
      throw error.json;
    })
    .unauthorized((error) => {
      return error.json;
    })
    .json();
}

export async function requestApi({ path, method, body }) {
  const headers = await getSession();

  return wretch(apiUrl(path))
    .body(body)
    .headers(headers)
    [method]()
    .notFound((error) => {
      throw error.json;
    })
    .unauthorized((error) => {
      return error.json;
    })
    .json();
}

export const registerWithPassword = async ({
  email,
  password,
  confirmPassword,
  invitationToken,
}) => {
  return await wretch(`${API_ROOT}/api/auth/password`)
    .put({
      email,
      password,
      invitation_token: invitationToken,
      password_confirmation: confirmPassword,
      accept_terms: true,
    })
    .json();
};

export const requestPasswordChange = async (email) => {
  ////console.log(APP_HOST + "reset-password?email=" + email);
  return await wretch(`${API_ROOT}/api/auth/password`)
    .post({
      email: email,
      redirect_url: APP_HOST + "reset-password?email=" + email,
    })
    .json();
};

export const patchPassword = async ({ params, headers }) => {
  ////console.log(params, headers);
  return await wretch(`${API_ROOT}/api/auth/password`)
    .headers(headers)
    .put(params)
    .json();
};

export const getEvents = async () => {
  const headers = await getSession();
  return await wretch(`${API_ROOT}/api/events`).headers(headers).get().json();
};

export const getEvent = async ({ queryKey }) => {
  const headers = await getSession();
  const { eventId } = queryKey[1];

  if (eventId !== "null") {
	return await wretch(
	  `${API_ROOT}/api/events/${eventId}`
	)
	.headers(headers)
	.get()
	.json();
  }
};

export const getInterests = async (eventIds, callback) => {
  const headers = await getSession();

  let promises = [];

  eventIds.forEach(async (id, i) => {
    promises.push(
      wretch(`${API_ROOT}/api/events/${id}/interests`)
        .headers(headers)
        .get()
        .json()
    );
  });

  Promise.all(promises)
    .then(function handleData(data) {
      const interests = data.map((d) => d.data).flat();
      callback(interests);
    })
    .catch(function handleError(error) {
      //console.log("Error" + error);
    });
};

export const getEventInterests = async ({ queryKey }) => {
  const headers = await getSession();
  const { eventId } = queryKey[1];

  if (eventId !== "null") {
    return await wretch(`${API_ROOT}/api/events/${eventId}/interests`)
      .headers(headers)
      .get()
      .json();
  }
};

export const patchUserInterests = async (params) => {
  const headers = await getSession();

  if (params.eventId !== "null") {
    return await wretch(`${API_ROOT}/api/events/${params.eventId}/interests`)
        .headers(headers)
        .post(params.payload)
        .json();
  }
};

export const getTimetable = async ({ queryKey }) => {
  const headers = await getSession();
  const { eventId } = queryKey[1];

  if (eventId !== "null") {
    return await wretch(`${API_ROOT}/api/events/${eventId}/programs`)
        .headers(headers)
        .get()
        .json();
  }
};

export const getTimeslots = async ({ queryKey }) => {
  const headers = await getSession();
  const { eventId } = queryKey[1];

  if (eventId !== "null") {
    return await wretch(`${API_ROOT}/api/events/${eventId}/timeslots`)
        .headers(headers)
        .get()
        .json();
  }
};

export const getAvailableMeetingSlots = async ({ queryKey }) => {
  const headers = await getSession();
  const { eventId, meetingRequesterId, userId } = queryKey[1];

  const payload = {
    requester_id: meetingRequesterId,
    receiver_id: userId,
  };

  if (eventId !== "null") {
    return await wretch(
        `${API_ROOT}/api/events/${eventId}/available_timeslots?requester_id=${meetingRequesterId}&receiver_id=${userId}`
    )
        .headers(headers)
        .get(payload)
        .json();
  }
};

export const getEventParticipant = async ({ queryKey }) => {
  const headers = await getSession();
  const { eventId, userId } = queryKey[1];

  if (eventId !== "null") {
    return await wretch(
        `${API_ROOT}/api/events/${eventId}/participants/${userId}`
    )
        .headers(headers)
        .get()
        .json();
  }
};
export const getEventParticipants = async ({ queryKey }) => {
  const headers = await getSession();
  const { eventId } = queryKey[1];

  if (eventId !== "null") {
    return await wretch(`${API_ROOT}/api/events/${eventId}/participants`)
        .headers(headers)
        .get()
        .json();
  }
};

export const getEventCompanies = async ({ queryKey }) => {
  const headers = await getSession();
  const { eventId } = queryKey[1];

  if (eventId !== "null") {
    return await wretch(`${API_ROOT}/api/events/${eventId}/companies`)
        .headers(headers)
        .get()
        .json();
  }
};

export const getEventPartners = async ({ queryKey }) => {
  const headers = await getSession();
  const { eventId } = queryKey[1];

  if (eventId !== "null") {
    return await wretch(`${API_ROOT}/api/events/${eventId}/partners`)
        .headers(headers)
        .get()
        .json();
  }
};

export const getEventPartner = async ({ queryKey }) => {
  const headers = await getSession();
  const { eventId, companyId } = queryKey[1];

  if (eventId !== "null") {
    return await wretch(
        `${API_ROOT}/api/events/${eventId}/companies/${companyId}`
    )
        .headers(headers)
        .get()
        .json();
  }
};

export const getEventTimeslot = async ({ queryKey }) => {
  const headers = await getSession();
  const { eventId, timeSlotId } = queryKey[1];

  if (eventId !== "null") {
    return await wretch(
        `${API_ROOT}/api/events/${eventId}/timeslots/${timeSlotId}`
    )
        .headers(headers)
        .get()
        .json();
  }
};

export const getAcceptedMeetingRequests = async ({ queryKey }) => {
  const headers = await getSession();
  const { eventId, includeOwnPartners } = queryKey[1];

  if (eventId !== "null") {
    let url = `${API_ROOT}/api/events/${eventId}/meetings/?type=accepted`;
    if (includeOwnPartners) {
      url = `${API_ROOT}/api/events/${eventId}/meetings/?type=accepted&include_own_partners=true`;
    }
    return await wretch(url).headers(headers).get().json();
  }
};

export const getPendingMeetingRequests = async ({ queryKey }) => {
  const headers = await getSession();
  const { eventId, includeOwnPartners } = queryKey[1];

  if (eventId !== "null") {
    let url = `${API_ROOT}/api/events/${eventId}/meetings/?type=pending`;
    if (includeOwnPartners) {
      url = `${API_ROOT}/api/events/${eventId}/meetings/?type=pending&include_own_partners=true`;
    }
    return await wretch(url).headers(headers).get().json();
  }
};

export const getRejectedMeetingRequests = async ({ queryKey }) => {
  const headers = await getSession();
  const { eventId, includeOwnPartners } = queryKey[1];

  if (eventId !== "null") {
    let url = `${API_ROOT}/api/events/${eventId}/meetings/?type=rejected`;
    if (includeOwnPartners) {
      url = `${API_ROOT}/api/events/${eventId}/meetings/?type=rejected&include_own_partners=true`;
    }
    return await wretch(url).headers(headers).get().json();
  }
};

export const getCancelledMeetingRequests = async ({ queryKey }) => {
  const headers = await getSession();
  const { eventId, includeOwnPartners } = queryKey[1];

  if (eventId !== "null") {
    let url = `${API_ROOT}/api/events/${eventId}/meetings/?type=cancelled`;
    if (includeOwnPartners) {
      url = `${API_ROOT}/api/events/${eventId}/meetings/?type=cancelled&include_own_partners=true`;
    }
    return await wretch(url).headers(headers).get().json();
  }
};

export const createMeetingRequest = async (params) => {
  const headers = await getSession();
  const { eventId, request } = params;

  if (eventId !== "null") {
    return await wretch(`${API_ROOT}/api/events/${eventId}/meetings`)
        .headers(headers)
        .post(request)
        .json();
  }
};

export const replyToMeetingRequest = async (params) => {
  const headers = await getSession();
  const { eventId, meetingId, payload } = params;

  if (eventId !== "null") {
    return await wretch(`${API_ROOT}/api/events/${eventId}/meetings/${meetingId}`)
        .headers(headers)
        .put(payload)
        .json();
  }
};

export const setMeetingHappened = async (params) => {
  const headers = await getSession();
  const { eventId, id } = params;

  if (eventId !== "null") {
	return await wretch(`${API_ROOT}/api/events/${eventId}/meetings/${id}`)
		.headers(headers)
		.patch(params)
		.json();
  }
}

export const getUserFavourites = async (params) => {
  const headers = await getSession();
  const { eventId, userId } = params.queryKey[1];

  if (eventId !== "null") {
    return await wretch(
        `${API_ROOT}/api/events/${eventId}/favourites?user_id=${userId}`
    )
        .headers(headers)
        .get()
        .json();
  }
};

export const markAsFavourite = async (params) => {
  const headers = await getSession();
  const { eventId, payload } = params;

  if (eventId !== "null") {
    return await wretch(`${API_ROOT}/api/events/${eventId}/favourites`)
        .headers(headers)
        .post(payload)
        .json();
  }
};

export const removeFromFavourites = async (params) => {
    const headers = await getSession();
    const {eventId, payload} = params;

  if (eventId !== "null") {
    await wretch(
        `${API_ROOT}/api/events/${eventId}/favourites?participant_id=${payload.participant_id}`
    )
        .headers(headers)
        .delete();
  }
};

export const getEventStreams = async ({ queryKey }) => {
    const headers = await getSession();
    const {eventId} = queryKey[1];

  if (eventId !== "null") {
    return await wretch(`${API_ROOT}/api/events/${eventId}/video_streams`)
        .headers(headers)
        .get()
        .json();
  }
};

export const eventLogger = async (params) => {
  const headers = await getSession();
  const { eventId } = params;
  return await wretch(`${API_ROOT}/api/events/${eventId}/event_loggers`)
    .headers(headers)
    .post(params.logs)
    .json();
};

export const createContactRequest = async (params) => {
  const headers = await getSession();
  const { eventId } = params;
  return await wretch(`${API_ROOT}/api/events/${eventId}/contact_requests`)
    .headers(headers)
    .post(params)
    .json();
}
